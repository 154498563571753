import { getSplitNames, getTreatments, track } from '@splitsoftware/splitio-redux';
import { selectBuyer, selectStore, selectUser } from 'containers/MainLayout/selectors';
import { Action } from 'redux';
import { all, call, put, takeLatest, select } from 'redux-saga/effects';
import ActionTypes from './constants';

const userTrafficTypeId = '4b70ee90-659b-11ea-95ab-0ad87b86182f';

function sendIdentities(values) {
  const splitAPIUrl = `https://api.split.io/internal/api/v2/trafficTypes/${userTrafficTypeId}/environments/${
    process.env.SPLITIO_ENVIRONMENT_ID
  }/identities/`;

  const data = {
    key: 'buyer-web',
    trafficTypeId: userTrafficTypeId,
    environmentId: process.env.SPLITIO_ENVIRONMENT_ID,
    values: values,
  };
  return fetch(splitAPIUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${process.env.SPLITIO_ADMIN_API_KEY}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify([data]),
  });
}

export function* getTreatmentsFromSplit() {
  const user = yield select(selectUser());
  const buyer = yield select(selectBuyer());
  const store = yield select(selectStore());

  const userId = user ? user.id : '';
  const buyerId = buyer ? buyer.id : '';
  const storeId = store ? store.id : '';

  const userData = { userId: userId, buyerId: buyerId, storeId: storeId };

  const splitNames = getSplitNames();

  if ((user || {}).id && (buyer || {}).id) {
    yield call(sendIdentities, userData);
  }

  if (splitNames.length) {
    track({ trafficType: 'user', eventType: 'INIT', properties: userData });
    const options = { splitNames: splitNames, attributes: userData, key: 'buyer-web' };
    yield put(getTreatments(options) as Action);
  }
}

export function* saga() {
  yield all([
    takeLatest(ActionTypes.SPLIT_READY, getTreatmentsFromSplit),
    takeLatest(ActionTypes.SPLIT_UPDATE, getTreatmentsFromSplit),
    takeLatest(ActionTypes.SEND_IDENTIDY, getTreatmentsFromSplit),
  ]);
}
