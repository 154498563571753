/*
 *
 * MainLayout reducer
 *
 */

import { ContainerActions, ContainerState, IBreadCrumbMarkup } from './types';
import transformCart, { sortCartSetByProductName } from './transformCart';

import ActionTypes from './constants';
import { combineReducers } from 'redux';
import { flatMap } from 'lodash';
import { NotificationMessage, ReadStatus } from 'types/schema';
import { pathnameChecker } from 'utils/url';
import qs from 'qs';
import { LANGUAGES } from 'utils/constants';

export const initialState: ContainerState = {
  store: { loading: true, location: {} },
  sideNav: false,
  cart: { loading: true, cartSet: [], notAvailableProducts: [] },
  addedProducts: {},
  user: { loading: true },
  buyer: { stores: [], loading: true, redInvoiceInfo: {}, enabledFeatures: [], name: '', id: '', userGroups: [] },
  categories: [],
  favoriteCategories: [],
  addingProducts: {},
  suppliers: { supplierNoFilter: [], supplierHasOrders: [] },
  lang: window.localStorage.getItem('lang') || 'vi',
  orderCountSummary: { countPendingOrders: 0 },
  updateQuantityProducts: [],
  orderDisabled: { orderDisabled: false },
  notification: { id: '', submitting: false },
  notificationMessages: { loading: false, data: [], totalPage: 0, totalResults: 0 },
  hasUnreadNotification: false,
  breadCrumbDataMarkup: '',
  promotionDataPopup: null,
  searchProductResults: { totalPage: 0, totalResults: 0, data: [], loading: false },
  buyerWebVersion: '',
};

function storeReducer(state = initialState.store, action: ContainerActions) {
  const { type, payload } = action as any;

  switch (type) {
    case ActionTypes.SET_SELECTED_STORE:
      return { ...payload, loading: false };
    default:
      return state;
  }
}

function sideNavReducer(state = initialState.sideNav, action: ContainerActions) {
  const { type } = action as any;
  switch (type) {
    case ActionTypes.SIDE_NAV_OPEN:
      localStorage.setItem('sideNavOpened', 'true');
      if (window.document.body.clientWidth < 1025) {
        window.document.body.classList.add('hideScroll');
      }
      return true;
    case ActionTypes.SIDE_NAV_CLOSE:
      localStorage.setItem('sideNavOpened', 'false');
      if (window.document.body.clientWidth < 1025) {
        window.document.body.classList.remove('hideScroll');
      }
      return false;
    case ActionTypes.SIDE_NAV_TOGGLE:
      const sideNav = !state;
      localStorage.setItem('sideNavOpened', sideNav.toString());
      if (window.document.body.clientWidth < 1025) {
        sideNav
          ? window.document.body.classList.add('hideScroll')
          : window.document.body.classList.remove('hideScroll');
      }
      return sideNav;
    default:
      return state;
  }
}

function cartReducer(state = initialState.cart, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_CART_SUCCESS: {
      return {
        ...state,
        loading: false,
        ...payload,
        cartSet: sortCartSetByProductName(payload.cartSet),
      };
    }
    case ActionTypes.UPDATE_CART_SUCCESS:
    case ActionTypes.REMOVE_ITEM_TO_CART_SUCCESS:
    case ActionTypes.ADD_TO_CART_SUCCESS:
      const cart = payload.carts ? transformCart(state, payload) : payload;
      return {
        ...state,
        loading: false,
        ...cart,
        cartSet: sortCartSetByProductName(cart.cartSet),
      };
    case ActionTypes.UPDATE_NOTE_TO_CART:
      return {
        ...state,
        cartSet: state.cartSet.map((cart) => ({
          ...cart,
          items: cart.items.map((item) =>
            item.product.id === payload.id ? { ...item, product: { ...item.product, note: payload.note } } : item,
          ),
        })),
      };
    case ActionTypes.UPDATE_NOT_AVAILABLE_PRODUCTS:
      return {
        ...state,
        notAvailableProducts: payload,
      };

    case ActionTypes.UPDATE_DELIVERY_NOTE:
      return {
        ...state,
        cartSet: payload.cartSet.map((cart) => ({
          ...state.cartSet.find((item) => item.supplier.id === cart.supplier.id),
          deliveryNotes: cart.deliveryNotes,
          deliveryDatetime: cart.deliveryDatetime,
          deliveryTimeSlots: cart.deliveryTimeSlots,
          earliestDate: cart.earliestDate,
          orderDeadline: cart.orderDeadline,
        })),
      }

    default:
      return state;
  }
}

function addingProductsReducer(state = initialState.addingProducts, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.ADDING_TO_CART:
      return { ...state, [payload.productId]: true };
    case ActionTypes.ADDING_MULTIPLE_TO_CART:
      return { ...state, ...payload };
    case ActionTypes.ADDED_MULTIPLE_TO_CART:
      return { ...state, ...payload };
    case ActionTypes.ADDED_TO_CART:
      return { ...state, [payload.productId]: false };
    default:
      return state;
  }
}

function addedProductsReducer(state = initialState.addedProducts, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_CART_SUCCESS: {
      const addedProducts = {};
      if (payload.cartSet[0]) {
        payload.cartSet[0].items.forEach((item) => {
          addedProducts[item.product.id] = Number(item.quantity.toFixed(3));
        });
      }
      return addedProducts;
    }
    case ActionTypes.UPDATE_CART_SUCCESS:
    case ActionTypes.REMOVE_ITEM_TO_CART_SUCCESS:
    case ActionTypes.ADD_TO_CART_SUCCESS:
      if (payload.carts) {
        return flatMap(payload.carts, ({ items }) => items).reduce((acc, { item: { id }, orderedQty }) => {
          acc[id] = Number(orderedQty);
          return acc;
        }, {});
      }
      return flatMap(payload.cartSet, ({ items }) => items).reduce((acc, { product: { id }, quantity }) => {
        acc[id] = quantity;
        return acc;
      }, {});
    default:
      return state;
  }
}

function userReducer(state = initialState.user, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_USER_SUCCESS:
      return { ...state, ...payload, loading: false };
    default:
      return state;
  }
}

function buyerReducer(state = initialState.buyer, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_BUYER_SUCCESS:
      return { ...payload, stores: payload.stores || [], loading: false };
    case ActionTypes.UPDATE_ENABLED_FEATURES:
      const enabledFeatures = payload.enabled
        ? [...state?.enabledFeatures, payload.feature]
        : state.enabledFeatures.filter((feature) => feature !== payload.feature);
      return {
        ...state,
        enabledFeatures: enabledFeatures,
      };
    default:
      return state;
  }
}

function categoriesReducer(state = initialState.categories, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_CATEGORIES_SUCCESS:
      return [
        // This should be translate & return from server side
        ...payload,
      ];
    default:
      return state;
  }
}

function favoriteCategoriesReducer(state = initialState.favoriteCategories, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_FAVORITE_CATEGORIES_SUCCESS:
      return [...payload];
    default:
      return state;
  }
}

function langReducer(state = initialState.lang, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.SET_LANG:
      window.localStorage.setItem('lang', payload);
      if (
        pathnameChecker.isProductPage(window.location.pathname) ||
        pathnameChecker.isCategoryPage(window.location.pathname)
      ) {
        if (payload === LANGUAGES.Vi) {
          const langRegex = new RegExp(`lang=(${Object.values(LANGUAGES).join('|')})&?`, 'g');
          window.location.href = window.location.href.replace(langRegex, '');
        } else {
          const search = qs.parse(window.location.search, { ignoreQueryPrefix: true });
          const query = qs.stringify({ lang: payload, ...search });
          window.location.href = window.location.href.replace(/\?[\w\d=]+/gi, '').concat(query ? `?${query}` : '');
        }
      } else {
        window.location.reload();
      }
      return payload;
    default:
      return state;
  }
}

function shortSuppliersReducer(state = initialState.suppliers, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_ALL_SUPPLIER_SUCCESS:
    case ActionTypes.FETCH_SHORT_SUPPLIER_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
}

function orderCountSummary(state = initialState.orderCountSummary, action: ContainerActions) {
  const { type, payload } = action as any;

  switch (type) {
    case ActionTypes.FETCH_RECENT_ORDERS_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
}

function updateQuantityProductsReducer(state = initialState.updateQuantityProducts, action: ContainerActions) {
  const { type, payload } = action as any;

  switch (type) {
    case ActionTypes.UPDATE_MULTIPLE_PRODUCT_QUANTITY:
      return [...state, payload];
    case ActionTypes.ADDED_MULTIPLE_TO_CART:
      return [];
    default:
      return state;
  }
}

function orderDisabled(state = initialState.orderDisabled, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.FETCH_ORDER_DISABLED_SUCCESS:
      return { ...state, ...payload };
    default:
      return state;
  }
}

function notificationReducer(state = initialState.notification, action: ContainerActions) {
  const { type, payload } = action as any;

  switch (type) {
    case ActionTypes.NOTIFICATION_REGISTER_TOKEN_REQUEST:
      return { ...state, id: '', submitting: true };
    case ActionTypes.NOTIFICATION_REGISTER_TOKEN_SUCCESS:
      return { ...state, id: payload.id, submitting: false };
    case ActionTypes.NOTIFICATION_REGISTER_TOKEN_FAILURE:
      return { ...state, submitting: false };

    case ActionTypes.NOTIFICATION_UNREGISTER_TOKEN:
      return { ...state, id: '' };
    default:
      return state;
  }
}

const notificationMessagesReducer = (state = initialState.notificationMessages, action: ContainerActions) => {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.NOTIFICATIONS_REQUEST:
      return { ...state, loading: true };
    case ActionTypes.NOTIFICATIONS_SUCCESS:
      return { ...state, ...payload, loading: false };
    case ActionTypes.NOTIFICATIONS_FAILURE:
      return { ...state, loading: false };

    case ActionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS: {
      return {
        ...state,
        data: state?.data?.map((item: NotificationMessage) => ({
          ...item,
          readStatus: item.id === payload.messageId ? ReadStatus.IsRead : item.readStatus,
        })),
      };
    }

    default: {
      return state;
    }
  }
};

const hasUnreadNotificationReducer = (state = initialState.hasUnreadNotification, action: ContainerActions) => {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.SET_HAS_UNREAD_NOTIFICATION:
      return payload;
    default: {
      return state;
    }
  }
};

function breadCrumbDataMarkupReducer(state = initialState.breadCrumbDataMarkup, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.SET_BREAD_CRUMB_DATA_MARKUP: {
      const result = {
        '@context': 'https://schema.org',
        '@type': 'BreadcrumbList',
        // '@id': `${window.location.href}/#breadcrumb`,
      };
      if (payload.length) {
        const itemListElement: IBreadCrumbMarkup[] = payload.map((item, index) => {
          const itemBreadCrumb: IBreadCrumbMarkup = {
            '@type': 'ListItem',
            position: index + 1,
            name: item.nameLocal,
          };
          if (item.path && index !== payload.length - 1) {
            itemBreadCrumb.item =
              process.env.APP_ENV === 'production'
                ? `https://kamereo.vn${item?.path}`
                : `https://buyer.qa.kamereo.vn${item?.path}`;
          }
          return itemBreadCrumb;
        });
        return JSON.stringify({
          ...result,
          itemListElement: itemListElement,
        });
      }
      return JSON.stringify(result);
    }
    default:
      return state;
  }
}

function promotionDataPopupReducer(state = initialState.promotionDataPopup, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.SET_PROMOTION_DATA_POPUP: {
      return payload;
    }
    default:
      return state;
  }
}

function searchProductResultsReducer(state = initialState.searchProductResults, action: ContainerActions) {
  const { type, payload } = action as any;
  switch (type) {
    case ActionTypes.SET_SEARCH_PRODUCT_RESULTS_LOADING: {
      return {
        ...state,
        loading: payload,
      };
    }
    case ActionTypes.SET_SEARCH_PRODUCT_RESULTS_SUCCESS: {
      return {
        ...state,
        totalPage: payload.totalPage || 0,
        totalResults: payload.totalResults || 0,
        data: payload.data?.length ? [...state.data, ...payload.data] : [],
      };
    }
    default:
      return state;
  }
}

function buyerWebVersionReducer(state = initialState.buyerWebVersion, action: ContainerActions) {
  const { payload, type } = action as any;
  switch (type) {
    case ActionTypes.SET_BUYER_WEB_VERSION: {
      return payload.version;
    }
    default:
      return state;
  }
}

export default combineReducers({
  store: storeReducer,
  sideNav: sideNavReducer,
  cart: cartReducer,
  addedProducts: addedProductsReducer,
  addingProducts: addingProductsReducer,
  updateQuantityProducts: updateQuantityProductsReducer,
  user: userReducer,
  buyer: buyerReducer,
  categories: categoriesReducer,
  favoriteCategories: favoriteCategoriesReducer,
  lang: langReducer,
  suppliers: shortSuppliersReducer,
  orderCountSummary: orderCountSummary,
  orderDisabled: orderDisabled,
  notification: notificationReducer,
  notificationMessages: notificationMessagesReducer,
  hasUnreadNotification: hasUnreadNotificationReducer,
  breadCrumbDataMarkup: breadCrumbDataMarkupReducer,
  promotionDataPopup: promotionDataPopupReducer,
  searchProductResults: searchProductResultsReducer,
  buyerWebVersion: buyerWebVersionReducer,
});
