import { action, createAsyncAction } from 'typesafe-actions';

import ActionTypes from './constants';
import { NotificationRegisterInput, Pagination, ProductNoteInput } from 'types/schema';
import { IRouteBreadCrumb } from 'components/BreadCrumb/type';

export const defaultAction = () => action(ActionTypes.DEFAULT_ACTION);
export const setFavorite = (data: any) => action(ActionTypes.SET_FAVORITE, data);
export const setLang = (data: any) => action(ActionTypes.SET_LANG, data);
export const setSelectedStore = (data: any) => action(ActionTypes.SET_SELECTED_STORE, data);
export const sideNavClose = () => action(ActionTypes.SIDE_NAV_CLOSE);
export const sideNavOpen = () => action(ActionTypes.SIDE_NAV_OPEN);
export const sideNavToggle = () => action(ActionTypes.SIDE_NAV_TOGGLE);
export const signout = () => action(ActionTypes.SIGNOUT);
export const updateEnabledFeatures = (data: any) => action(ActionTypes.UPDATE_ENABLED_FEATURES, data);
export const refreshCart = () => action(ActionTypes.REFRESH_CART);
export const updateNotAvailableProducts = (data: any) => action(ActionTypes.UPDATE_NOT_AVAILABLE_PRODUCTS, data);

export const cart = createAsyncAction(
  ActionTypes.FETCH_CART_REQUEST,
  ActionTypes.FETCH_CART_SUCCESS,
  ActionTypes.FETCH_CART_FAILURE,
)<void, {}, Error>();
export const user = createAsyncAction(
  ActionTypes.FETCH_USER_REQUEST,
  ActionTypes.FETCH_USER_SUCCESS,
  ActionTypes.FETCH_USER_FAILURE,
)<void, {}, Error>();
export const buyer = createAsyncAction(
  ActionTypes.FETCH_BUYER_REQUEST,
  ActionTypes.FETCH_BUYER_SUCCESS,
  ActionTypes.FETCH_BUYER_FAILURE,
)<void, {}, Error>();
export const categories = createAsyncAction(
  ActionTypes.FETCH_CATEGORIES_REQUEST,
  ActionTypes.FETCH_CATEGORIES_SUCCESS,
  ActionTypes.FETCH_CATEGORIES_FAILURE,
)<void, {}, Error>();
export const favoriteCategories = createAsyncAction(
  ActionTypes.FETCH_FAVORITE_CATEGORIES_REQUEST,
  ActionTypes.FETCH_FAVORITE_CATEGORIES_SUCCESS,
  ActionTypes.FETCH_FAVORITE_CATEGORIES_FAILURE,
)<void, {}, Error>();
export const favorite = createAsyncAction(
  ActionTypes.FAVORITE_REQUEST,
  ActionTypes.FAVORITE_SUCCESS,
  ActionTypes.FAVORITE_FAILURE,
)<void, {}, Error>();
export const setProductNote = (data: ProductNoteInput) => action(ActionTypes.SET_PRODUCT_NOTE, data);
export const productNote = createAsyncAction(
  ActionTypes.SET_PRODUCT_NOTE_REQUEST,
  ActionTypes.SET_PRODUCT_NOTE_SUCCESS,
  ActionTypes.SET_PRODUCT_NOTE_FAILURE,
)<void, {}, Error>();
export const addingToCart = (data: any) => action(ActionTypes.ADDING_TO_CART, data);
export const updateNoteToCard = (data: any) => action(ActionTypes.UPDATE_NOTE_TO_CART, data);
export const addedToCart = (data: any) => action(ActionTypes.ADDED_TO_CART, data);
export const addItemCart = createAsyncAction(
  ActionTypes.ADD_TO_CART_REQUEST,
  ActionTypes.ADD_TO_CART_SUCCESS,
  ActionTypes.ADD_TO_CART_FAILURE,
)<void, {}, Error>();
export const updateProductQuantity = (data: any) => action(ActionTypes.UPDATE_PRODUCT_QUANTITY, data);
export const updateMultipleProductQuantity = (data: any) => action(ActionTypes.UPDATE_MULTIPLE_PRODUCT_QUANTITY, data);
export const addingMultipleToCart = (data: any) => action(ActionTypes.ADDING_MULTIPLE_TO_CART, data);
export const addedMultipleToCart = (data: any) => action(ActionTypes.ADDED_MULTIPLE_TO_CART, data);
export const submitMultipleProductQuantity = () => action(ActionTypes.SUBMIT_MULTIPLE_PRODUCT_QUANTITY);
export const updateCart = (data: any) => action(ActionTypes.UPDATE_CART, data);
export const updateItemCart = createAsyncAction(
  ActionTypes.UPDATE_CART_REQUEST,
  ActionTypes.UPDATE_CART_SUCCESS,
  ActionTypes.UPDATE_CART_FAILURE,
)<void, {}, Error>();
export const removeItemToCart = (data: any) => action(ActionTypes.REMOVE_ITEM_TO_CART, data);
export const removeItemCart = createAsyncAction(
  ActionTypes.REMOVE_ITEM_TO_CART_REQUEST,
  ActionTypes.REMOVE_ITEM_TO_CART_SUCCESS,
  ActionTypes.REMOVE_ITEM_TO_CART_FAILURE,
)<void, {}, Error>();
export const shortSupplier = createAsyncAction(
  ActionTypes.FETCH_SHORT_SUPPLIER_REQUEST,
  ActionTypes.FETCH_SHORT_SUPPLIER_SUCCESS,
  ActionTypes.FETCH_SHORT_SUPPLIER_FAILURE,
)<void, {}, Error>();
export const allSupplier = createAsyncAction(
  ActionTypes.FETCH_ALL_SUPPLIER_REQUEST,
  ActionTypes.FETCH_ALL_SUPPLIER_SUCCESS,
  ActionTypes.FETCH_ALL_SUPPLIER_FAILURE,
)<void, {}, Error>();
export const recentOrders = createAsyncAction(
  ActionTypes.FETCH_RECENT_ORDERS_REQUEST,
  ActionTypes.FETCH_RECENT_ORDERS_SUCCESS,
  ActionTypes.FETCH_RECENT_ORDERS_FAILURE,
)<void, {}, Error>();
export const orderDisabled = createAsyncAction(
  ActionTypes.FETCH_ORDER_DISABLED_REQUEST,
  ActionTypes.FETCH_ORDER_DISABLED_SUCCESS,
  ActionTypes.FETCH_ORDER_DISABLED_FAILURE,
)<void, {}, Error>();

export const notificationRegisterTokenAction = (input: NotificationRegisterInput) =>
  action(ActionTypes.NOTIFICATION_REGISTER_TOKEN, input);
export const notificationRegisterTokenAsyncAction = createAsyncAction(
  ActionTypes.NOTIFICATION_REGISTER_TOKEN_REQUEST,
  ActionTypes.NOTIFICATION_REGISTER_TOKEN_SUCCESS,
  ActionTypes.NOTIFICATION_REGISTER_TOKEN_FAILURE,
)<void, {}, Error>();

export const notificationUnregisterTokenAction = () => action(ActionTypes.NOTIFICATION_UNREGISTER_TOKEN);
export const notificationUnregisterTokenAsyncAction = createAsyncAction(
  ActionTypes.NOTIFICATION_UNREGISTER_TOKEN_REQUEST,
  ActionTypes.NOTIFICATION_UNREGISTER_TOKEN_SUCCESS,
  ActionTypes.NOTIFICATION_UNREGISTER_TOKEN_FAILURE,
)<void, {}, Error>();

export const markNotificationAsRead = (input: { messageId: string }) =>
  action(ActionTypes.MARK_NOTIFICATION_AS_READ, input);
export const markNotificationAsReadAsyncAction = createAsyncAction(
  ActionTypes.MARK_NOTIFICATION_AS_READ_REQUEST,
  ActionTypes.MARK_NOTIFICATION_AS_READ_SUCCESS,
  ActionTypes.MARK_NOTIFICATION_AS_READ_FAILURE,
)<void, {}, Error>();

export const notifications = (params: Pagination) => action(ActionTypes.NOTIFICATIONS, params);
export const notificationsAsyncAction = createAsyncAction(
  ActionTypes.NOTIFICATIONS_REQUEST,
  ActionTypes.NOTIFICATIONS_SUCCESS,
  ActionTypes.NOTIFICATIONS_FAILURE,
)<{}, {}, Error>();
export const setPromotionDataPopup = (payload: any) => action(ActionTypes.SET_PROMOTION_DATA_POPUP, payload);

export const setHasUnreadNotification = (payload: boolean) => action(ActionTypes.SET_HAS_UNREAD_NOTIFICATION, payload);
export const setBreadCrumbDataMarkup = (payload: IRouteBreadCrumb[]) =>
  action(ActionTypes.SET_BREAD_CRUMB_DATA_MARKUP, payload);

export const setSearchProductResults = createAsyncAction(
  ActionTypes.SET_SEARCH_PRODUCT_RESULTS_REQUEST,
  ActionTypes.SET_SEARCH_PRODUCT_RESULTS_SUCCESS,
  ActionTypes.SET_SEARCH_PRODUCT_RESULTS_FAILURE,
)<void, {}, Error>();
export const setSearchProductResultsLoading = (payload: boolean) =>
  action(ActionTypes.SET_SEARCH_PRODUCT_RESULTS_LOADING, payload);
export const buyerWebVersion = () => action(ActionTypes.BUYER_WEB_VERSION);
export const setBuyerWebVersion = (payload: any) => action(ActionTypes.SET_BUYER_WEB_VERSION, payload);

export const updateDeliveryNote = (data: any) => action(ActionTypes.UPDATE_DELIVERY_NOTE, data);
